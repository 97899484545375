import "core-js/modules/esnext.async-iterator.filter.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.async-iterator.for-each.js";
import "core-js/modules/esnext.iterator.for-each.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_c2yczioqaaww5hjkhfaojzr7cu/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_c2yczioqaaww5hjkhfaojzr7cu/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["event_name"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.push.js";
export const syncMirrorGAUserIds = gaUserId => {
  window.MIRROR_EVENT && window.MIRROR_EVENT.push({
    event: "si",
    payload: {
      ga: [gaUserId]
    }
  });
};
export const setContentInterest = payload => {
  var _window$MIRROR_EVENT, _window$MIRROR_EVENT2;
  if (!window.MIRROR_EVENT) return;
  (_window$MIRROR_EVENT = window.MIRROR_EVENT) === null || _window$MIRROR_EVENT === void 0 || _window$MIRROR_EVENT.push({
    event: "ce",
    name: "content_interest",
    payload
  });
  (_window$MIRROR_EVENT2 = window.MIRROR_EVENT) === null || _window$MIRROR_EVENT2 === void 0 || _window$MIRROR_EVENT2.push({
    event: "ta",
    payload,
    source: "content_interest"
  });
};
export function sendMirrorCustomEvent(_ref, _options) {
  let {
      event_name
    } = _ref,
    eventParameters = _objectWithoutProperties(_ref, _excluded);
  if (window.MIRROR_EVENT === undefined) return;
  window.MIRROR_EVENT.push({
    event: "ce",
    name: event_name,
    payload: _objectSpread({}, eventParameters)
  });
}
export const mirrorVideoAdd = (player, name) => {
  var _window$MIRROR, _window$MIRROR2;
  if (!((_window$MIRROR = window.MIRROR) !== null && _window$MIRROR !== void 0 && _window$MIRROR.video)) return;
  (_window$MIRROR2 = window.MIRROR) === null || _window$MIRROR2 === void 0 || (_window$MIRROR2 = _window$MIRROR2.video) === null || _window$MIRROR2 === void 0 || _window$MIRROR2.add(player, name);
};
export const mirrorVideoLoad = (adId, name) => {
  var _window$MIRROR3, _window$MIRROR4;
  if (!((_window$MIRROR3 = window.MIRROR) !== null && _window$MIRROR3 !== void 0 && _window$MIRROR3.video)) return;
  (_window$MIRROR4 = window.MIRROR) === null || _window$MIRROR4 === void 0 || (_window$MIRROR4 = _window$MIRROR4.video) === null || _window$MIRROR4 === void 0 || _window$MIRROR4.load(adId, name);
};