import { NoSsr } from "@mui/base";
import { notEmpty } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { type FunctionComponent, useMemo } from "react";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { HeaderTab } from "scmp-app/components/tracking/google-analytics-4/types";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import { pathname as homePathName } from "scmp-app/pages/home";
import { pathname as plusPathName } from "scmp-app/pages/plus";

import { Container, SCMPPlusTabsList } from "./styles";

type Props = {
  className?: string;
  isPlus: boolean;
};

export const SCMPPlusTabs: FunctionComponent<Props> = ({ className, isPlus }) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const plusTabPath = useMemo(() => {
    if (!notEmpty(asyncRosettaState) || asyncRosettaState?.status === "loading") return "";
    return plusPathName;
  }, [asyncRosettaState]);

  const getTabClickHandler = (headerTab: HeaderTab) => () => {
    sendGA4Tracking({
      action: "click",
      category: "header",
      customized_parameters: {
        header: headerTab,
      },
      subcategory: "",
    });
  };

  return (
    <Container className={className}>
      <NoSsr>
        <SCMPPlusTabsList
          $isActive={!isPlus}
          $isPlus={isPlus}
          onClick={getTabClickHandler("scmp")}
          pathname={homePathName}
        >
          SCMP.COM
        </SCMPPlusTabsList>
        {plusTabPath && (
          <SCMPPlusTabsList
            $isActive={isPlus}
            $isPlus={isPlus}
            onClick={getTabClickHandler("scmp_plus")}
            pathname={plusTabPath}
          >
            SCMP <i>PLUS</i>
          </SCMPPlusTabsList>
        )}
      </NoSsr>
    </Container>
  );
};

SCMPPlusTabs.displayName = "SCMPPlusTabs";
